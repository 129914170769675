<template>
  <div>
    <div class="m_home_page" v-if="isMobile">
      <div class="banner_wrap">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="@/assets/m_banner_1.png" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/2123.png" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/2041.png" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/2040.png" />
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
        </div>
      </div>

      <div class="core_wrap">
        <p class="core_title">核心技术</p>
        <p class="core_des">
          以3D智能视觉、IOT、AI、5G、云计算、大数据等高新技术赋能建筑行业数字化和工业化
        </p>
        <div class="swiper_core">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="core_block">
                <div class="block_img">
                  <img src="@/assets/core_m_1.png" alt="" srcset="" />
                  <p>核心算法</p>
                </div>
                <div class="block_right">
                  <div class="blocl_item">高精度3D扫描重建和环路闭合算法</div>
                  <div class="blocl_item">
                    <p>光机电系统标定校准算法</p>
                  </div>
                  <div class="blocl_item">人工智能建模算法</div>
                  <div class="blocl_item">
                    <p>人工智能语义识别算法</p>
                  </div>
                  <div class="blocl_item">
                    <p>人工智能指标算量算法</p>
                  </div>
                  <div class="blocl_item">
                    <p>人工智能自动平面分割算法</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="core_block">
                <div class="block_img">
                  <p>光机电硬件平台系统</p>
                  <img src="@/assets/core_m_2.png" alt="" srcset="" />
                </div>
                <div class="block_right center">
                  <div class="blocl_item">高精度光电码盘马达控制</div>
                  <div class="blocl_item"><p>激光雷达</p></div>
                  <div class="blocl_item">水平传感器协同</div>
                  <div class="blocl_item">实时边缘计算</div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="core_block">
                <div class="block_img">
                  <img src="@/assets/core_m_3.png" alt="" srcset="" />
                  <p>应用软件系统+SAAS平台</p>
                </div>
                <div class="block_right center">
                  <div class="blocl_item">智能建模BIM比对</div>
                  <div class="blocl_item">实测实量</div>
                  <div class="blocl_item">智慧工地可视化</div>
                  <div class="blocl_item">数据分析决策</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
        </div>
      </div>

      <div class="cloud_wrap">
        <p class="cloud_title">盎锐云端</p>
        <p class="cloud_des">
          通过2D图纸、BIM模型、3D点云的融合与匹配，支持建筑全生命周期的数字化建模，无缝对接AutoCAD、Revit能通过多站点扫描与拼接感知复杂建筑空间
        </p>
        <div class="cloud_line">
          <div class="cloud_item cloud_bg1">
            <img src="@/assets/cloud_m_icon1.png" alt="" />
            <p>智能化云端管理平台</p>
          </div>
          <div class="cloud_item cloud_bg2">
            <img src="@/assets/cloud_m_icon2.png" alt="" />
            <p>数据储存</p>
          </div>
          <div class="cloud_item cloud_bg3">
            <img src="@/assets/cloud_m_icon3.png" alt="" />
            <p>3D数据上墙</p>
          </div>
          <div class="cloud_item cloud_bg4">
            <img src="@/assets/cloud_m_icon4.png" alt="" />
            <p>数据统计报表</p>
          </div>
        </div>

        <div class="cloud_item cloud_bg5">
          <img src="@/assets/cloud_m_icon5.png" alt="" />
          <p>用户权限管理</p>
        </div>
      </div>

      <div class="adv_wrap">
        <p class="adv_title">竞争优势</p>
        <p class="adv_des">
          具备完整的设计、研发以及生产能力，提供全栈式解决方案，具备低成本、高性能的特点
        </p>
        <div class="adv_line">
          <div class="adv_item">
            <p class="num"><span>0.36</span> 元/㎡</p>
            <p class="title">降低成本</p>
            <p class="des">实测实量成本由3.95元/㎡</p>
            <p class="des">降至0.36元/㎡</p>
          </div>
          <div class="adv_item">
            <p class="num"><span>3.5</span> 分钟/房间</p>
            <p class="title">提升效率</p>
            <p class="des">实测实量时效由20分钟/房间</p>
            <p class="des">降至3.5分钟/房间</p>
          </div>
          <div class="adv_item">
            <p class="num"><span>1.5</span> mm/精度</p>
            <p class="title">提高品质</p>
            <p class="des">自动形成报表</p>
            <p class="des">实现3D建模对应上墙</p>
            <p class="des">将碎片化数据提升为结构化数据</p>
          </div>
          <div class="adv_item">
            <p class="num"><span>6</span> 方复用</p>
            <p class="title">数据复用</p>
            <p class="des">各方复用数据</p>
            <p class="des">大幅减少协同和纠错成本</p>
          </div>
        </div>
      </div>

      <div class="tab_wrap">
        <p class="tab_title">打破传统 科技创新</p>
        <p class="tab_des">
          盎锐数字化测量机器人，用智能测量赋能智能建造，解决了人工测量的痛点
        </p>
        <div class="tab_center">
          <img src="@/assets/tab1.png" />
          <img class="vs" src="@/assets/vs.png" />
          <img src="@/assets/tab2.png" />
        </div>
      </div>

      <div class="news_wrap">
        <p class="news_title">新闻资讯</p>
        <p class="news_des">传递建筑行业动态和盎锐最新资讯</p>
        <div class="news_center">
          <div
            class="news_item"
            v-for="(item, index) in news"
            :key="index"
            @click="showMore(item.id)"
          >
            <img
              :src="'https://en-api-hk.unre.com/tempFiles/' + item.coverImage"
            />
            <p class="item_title">
              {{ item.zhTitle }}
            </p>
            <p class="item_time">{{ dayjs(item.date).format("YYYY-MM-DD") }}</p>
          </div>
        </div>
        <div class="more_btn" @click="goTo('/News')">查看更多</div>
      </div>

      <div class="partner_wrap">
        <p class="partner_title">合作伙伴</p>
        <p class="partner_des">
          盎锐已为超过200家客户提供全自动工程质量解决方案
        </p>
        <img src="@/assets/patener_mobile.png" />
      </div>
    </div>
    <div class="home_page" v-else>
      <!-- <img alt="Vue logo" src="@/assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
      <div class="banner_wrap">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="@/assets/banner1.png" />
            </div>

            <div class="swiper-slide">
              <video
                id="video"
                loop
                muted
                autoplay
                webkit-playsinline="true"
                x-webkit-airplay="true"
                x5-playsinline="true"
                playsinline="true"
                x5-video-player-type="h5-page"
                src="https://unre-ucl360-services.oss-cn-hangzhou.aliyuncs.com/www-video/video.6fcc3930.mp4"
              ></video>
              <div class="video_fix">
                <img class="video_img" src="@/assets/video_text.png" />
                <p class="video_text">从物理空间到数字空间，让空间数据可感知</p>
              </div>
            </div>
            <div class="swiper-slide">
              <img src="@/assets/banner2.png" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/banner3.png" />
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
        </div>

        <div class="mouse-hover-left">
          <div class="swiper-button-prev"></div>
        </div>

        <div class="mouse-hover-right">
          <div class="swiper-button-next"></div>
        </div>
      </div>
      <div class="core_wrap fadeInUp slow animated">
        <p class="core_title">核心技术</p>
        <p class="core_des">
          以3D智能视觉、IOT、AI、5G、云计算、大数据等高新技术赋能建筑行业数字化和工业化
        </p>
        <div class="core_block">
          <img src="@/assets/core1.png" alt="" srcset="" />
          <p
            style="
              font-size: 0.15rem;
              position: absolute;
              bottom: 24px;
              left: 24px;
              color: #fff;
            "
          >
            核心算法
          </p>
          <div class="block_right">
            <div class="blocl_item">
              <p>高精度3D扫描重建</p>
              <p>和环路闭合算法</p>
            </div>
            <div class="blocl_item">
              <p>光机电系统标定</p>
              <p>校准算法</p>
            </div>
            <div class="blocl_item">人工智能建模算法</div>
            <div class="blocl_item">
              <p>人工智能语义</p>
              <p>识别算法</p>
            </div>
            <div class="blocl_item">
              <p>人工智能指标</p>
              <p>算量算法</p>
            </div>
            <div class="blocl_item">
              <p>人工智能自动</p>
              <p>平面分割算法</p>
            </div>
          </div>
        </div>
        <div class="core_block">
          <div class="block_right center">
            <div class="blocl_item">
              <p>高精度光电码盘</p>
              <p>马达控制</p>
            </div>
            <div class="blocl_item"><p>激光雷达</p></div>
            <div class="blocl_item">水平传感器协同</div>
            <div class="blocl_item">实时边缘计算</div>
          </div>
          <p
            style="
              font-size: 0.15rem;
              position: absolute;
              bottom: 24px;
              right: 24px;
              color: #fff;
            "
          >
            光机电硬件平台系统
          </p>
          <img src="@/assets/core2.png" alt="" srcset="" />
        </div>
        <div class="core_block">
          <img src="@/assets/core3.png" alt="" srcset="" />
          <p
            style="
              font-size: 0.15rem;
              position: absolute;
              bottom: 24px;
              left: 24px;
              color: #fff;
            "
          >
            应用软件系统+SAAS平台
          </p>
          <div class="block_right center">
            <div class="blocl_item">智能建模BIM比对</div>
            <div class="blocl_item">实测实量</div>
            <div class="blocl_item">智慧工地可视化</div>
            <div class="blocl_item">数据分析决策</div>
          </div>
        </div>
      </div>
      <div class="cloud_wrap fadeInUp slow animated wow">
        <p class="cloud_title">盎锐云端</p>
        <p class="cloud_des">
          通过2D图纸、BIM模型、3D点云的融合与匹配，支持建筑全生命周期的数字化建模
        </p>
        <p class="cloud_des">
          无缝对接AutoCAD、Revit能通过多站点扫描与拼接感知复杂建筑空间
        </p>
        <div class="cloud_line">
          <div class="cloud_item cloud_bg1"><p>智能化云端管理平台</p></div>
          <div class="cloud_item cloud_bg2"><p>数据储存</p></div>
          <div class="cloud_item cloud_bg3"><p>3D数据上墙</p></div>
        </div>
        <div class="cloud_line">
          <div class="cloud_item cloud_bg4"><p>数据统计报表</p></div>
          <div class="cloud_item cloud_bg5"><p>用户权限管理</p></div>
        </div>
      </div>
      <div class="adv_wrap fadeInUp slow animated wow">
        <p class="adv_title">竞争优势</p>
        <p class="adv_des">
          具备完整的设计、研发以及生产能力，提供全栈式解决方案，具备低成本、高性能的特点
        </p>
        <div class="adv_line">
          <div class="adv_item">
            <p class="num"><span>0.36</span> 元/㎡</p>
            <p class="title">降低成本</p>
            <p class="des">实测实量成本由3.95元/㎡</p>
            <p class="des">降至0.36元/㎡</p>
          </div>
          <div class="adv_item">
            <p class="num"><span>3.5</span> 分钟/房间</p>
            <p class="title">提升效率</p>
            <p class="des">实测实量时效由20分钟/房间</p>
            <p class="des">降至3.5分钟/房间</p>
          </div>
          <div class="adv_item">
            <p class="num"><span>1.5</span> mm/精度</p>
            <p class="title">提高品质</p>
            <p class="des">自动形成报表，实现3D建模对应上墙</p>
            <p class="des">将碎片化数据提升为结构化数据</p>
          </div>
          <div class="adv_item">
            <p class="num"><span>6</span> 方复用</p>
            <p class="title">数据复用</p>
            <p class="des">1方测量，6方复用，各方复用数据</p>
            <p class="des">大幅减少协同和纠错成本</p>
          </div>
        </div>
      </div>
      <div class="tab_wrap fadeInUp slow animated wow">
        <p class="tab_title">打破传统 科技创新</p>
        <p class="tab_des">
          盎锐数字化测量机器人，用智能测量赋能智能建造，解决了人工测量的痛点
        </p>
        <div class="tab_center">
          <img src="@/assets/tab1.png" />
          <img class="vs" src="@/assets/vs.png" />
          <img src="@/assets/tab2.png" />
        </div>
      </div>

      <div class="news_wrap fadeInUp slow animated wow">
        <p class="news_title">新闻资讯</p>
        <p class="news_des">传递建筑行业动态和盎锐最新资讯</p>
        <div class="news_center">
          <div
            class="news_item"
            v-for="(item, index) in news"
            :key="index"
            @click="showMore(item.id)"
          >
            <img
              :src="'https://en-api-hk.unre.com/tempFiles/' + item.coverImage"
            />
            <p class="item_title">
              {{ item.zhTitle }}
            </p>
            <p class="item_time">{{ dayjs(item.date).format("YYYY-MM-DD") }}</p>
          </div>
        </div>
        <div class="more_btn" @click="goTo('/News')">查看更多</div>
      </div>

      <div class="partner_wrap fadeInUp slow animated wow">
        <p class="partner_title">合作伙伴</p>
        <p class="partner_des">
          盎锐已为超过200家客户提供全自动工程质量解决方案
        </p>
        <img src="@/assets/patener_merge_1x.png" />
        <p style="color: #808080">以上排名不分先后</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import { isMobile } from "@/utils";
import { WOW } from "wowjs";
import axios from "axios";
import dayjs from "dayjs";
export default {
  name: "Home",
  data() {
    return {
      isMobile: false,
      news: [],
      dayjs: dayjs,
    };
  },
  components: {},
  created() {
    this.isMobile = isMobile();
    this.getList(1);
  },
  mounted() {
    this.$nextTick(() => {
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
    this.initSwiper();
  },
  methods: {
    getList(page) {
      axios
        .get("https://en-api-hk.unre.com/blogs/query", {
          params: {
            page: page,
            pageSize: 3,
          },
        })
        .then((response) => {
          this.news = response.data.data.rows;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goTo(path) {
      this.$router.push({
        path: path,
      });
    },
    showMore(id) {
      this.$router.push({
        path: "/Info",
        query: { id: id },
      });
    },
    initSwiper() {
      var audioE2 = document.getElementById("video");
      if (audioE2) {
        audioE2.onloadedmetadata = function () {
          var swiper = new Swiper(".swiper-container", {
            pagination: {
              el: ".swiper-pagination",
            },
            navigation: {
              nextEl: ".banner_wrap .swiper-button-next",

              prevEl: ".banner_wrap .swiper-button-prev",
            },
            autoplay: {
              delay: 19000,

              stopOnLastSlide: false,

              disableOnInteraction: false,
            },
          });
        };
      }
      if (this.isMobile) {
        var swiper = new Swiper(".swiper-container", {
          pagination: {
            el: ".swiper-pagination",
          },

          autoplay: {
            delay: 19000,

            stopOnLastSlide: false,

            disableOnInteraction: false,
          },
        });
        var core = new Swiper(".swiper_core", {
          pagination: {
            el: ".swiper-pagination",
          },

          autoplay: {
            delay: 1115000,

            stopOnLastSlide: false,

            disableOnInteraction: false,
          },
        });
      }
    },
  },
};
</script>

<style lang="less">
.home_page {
  .swiper-container {
    .swiper-pagination {
      bottom: 20px;
    }
    .swiper-pagination-bullet {
      width: 24px;
      height: 4px;
      background: #fff;
      border-radius: 0;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      width: 24px;
      height: 4px;
      background: #07ace7;
      border-radius: 0;
    }
    .swiper-slide {
      position: relative;
      .video_fix {
        position: absolute;
        top: 40%;
        left: 52%;
      }
      .video_text {
        text-align: left;
        color: #f2f2f2;
        font-size: 0.127rem;
      }
      .video_img {
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        display: block;
      }
      video {
        width: 100%;
        display: block;
      }
    }
  }
  .banner_wrap {
    .swiper-button-next,
    .swiper-button-prev {
      top: 1.7rem;
      padding: 30px;
      height: 2rem;
      color: #fff;
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    }
  }
  .core_wrap {
    background-color: #2d3740;
    padding: 60px 0;

    .core_title {
      color: #fcfcfc;
      font-size: 36px;
      line-height: 48px;
      font-weight: 600;
      margin-bottom: 18px;
    }
    .core_des {
      line-height: 24px;
      color: #cccccc;
      font-size: 0.095rem;
      margin-bottom: 56px;
    }
    .core_block {
      background: #3f4d59;
      border-radius: 6px 6px 6px 6px;
      display: flex;
      height: 1.69rem;
      width: 7.92rem;
      margin: 0 auto;
      margin-bottom: 0.25rem;
      position: relative;
      img {
        width: 3.59rem;
      }
      .block_right {
        padding: 0.24rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        &.center {
          justify-content: center;
          .blocl_item {
            width: 1.2rem;
            margin: 0 0.1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            line-height: 0.18rem;
          }
        }
        .blocl_item {
          line-height: 0.18rem;
          width: 1.2rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 0.53rem;
          background: rgba(255, 255, 255, 0.2);

          box-shadow: 0.04rem 0.05rem 0.27rem 0.01rem rgba(204, 204, 204, 0.16);

          border-radius: 0.03rem 0.03rem 0.03rem 0.03rem;
          color: #f2f2f2;

          font-size: 0.094rem;
        }
      }
    }
  }
  .cloud_wrap {
    padding: 0.32rem 0;
    background: url(../assets/cloud_bg.png) no-repeat;
    background-size: cover;
    .cloud_title {
      font-size: 36px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 18px;
    }
    .cloud_des {
      color: #808080;
      font-size: 0.095rem;
      line-height: 0.16rem;
    }
    .cloud_line {
      display: flex;
      justify-content: space-between;
      margin-top: 0.25rem;
      padding: 0 1.04rem;
      .cloud_item {
        // margin: 0 47px;
        width: 2.31rem;
        height: 0.81rem;
        color: #00394d;

        font-size: 0.108rem;
        p {
          margin-top: 0.36rem;
          text-align: left;
          margin-left: 0.48rem;
        }
      }
      .cloud_bg1 {
        background: url(../assets/cloud_1.png) no-repeat;
        background-size: cover;
      }
      .cloud_bg2 {
        background: url(../assets/cloud_2.png) no-repeat;
        background-size: cover;
      }
      .cloud_bg3 {
        background: url(../assets/cloud_3.png) no-repeat;
        background-size: cover;
      }
      .cloud_bg4 {
        background: url(../assets/cloud_4.png) no-repeat;
        background-size: cover;
        margin-right: 0.245rem;
      }
      .cloud_bg5 {
        background: url(../assets/cloud_5.png) no-repeat;
        background-size: cover;
        margin-left: 0.245rem;
      }
      &:last-child {
        justify-content: center;
      }
    }
  }
  .adv_wrap {
    background: url(../assets/adv.png) no-repeat;
    background-size: cover;
    padding: 0.32rem 1.04rem;
    .adv_title {
      color: #fcfcfc;
      font-weight: 600;
      font-size: 36px;
      margin-bottom: 18px;
    }
    .adv_des {
      margin-bottom: 46px;

      color: #cccccc;
      font-size: 0.095rem;
    }
    .adv_line {
      display: flex;
      justify-content: space-between;
      .adv_item {
        width: 1.67rem;
        height: 1.77rem;
        background: #394856;
        border-radius: 0.03rem 0.03rem 0.03rem 0.03rem;
        opacity: 0.9;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .num {
        margin-bottom: 0.13rem;

        font-size: 0.13rem;
        color: #02aeec;
        span {
          font-size: 0.25rem;
          font-weight: 500;
        }
      }
      .title {
        margin-bottom: 0.13rem;
        color: #f5f5f5;

        font-size: 0.13rem;
      }
      .des {
        line-height: 0.11rem;
        color: #cccccc;

        font-size: 14px;
      }
    }
  }
  .tab_wrap {
    padding: 0.32rem 0;
    background: #fafafa;
    .tab_title {
      color: #333333;
      font-size: 36px;
      margin-bottom: 0.12rem;
      font-weight: 600;
    }
    .tab_des {
      color: #808080;
      font-size: 0.095rem;
    }
    .tab_center {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      img {
        max-width: 100%;
        width: 3rem;
      }
      .vs {
        margin: 0 -0.1rem 0 -0.19rem;
        width: 0.4rem;
        margin-top: 0.35rem;
      }
    }
  }
  .news_wrap {
    background: url(../assets/news_bg.png);
    background-size: cover;
    padding: 0.32rem 0;
    .news_title {
      color: #333333;
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 0.1rem;
    }
    .news_des {
      margin-bottom: 0.25rem;
      color: #808080;
      font-size: 0.095rem;
    }
    .news_center {
      display: flex;
      justify-content: space-between;
      padding: 0 1.04rem;
      .news_item {
        cursor: pointer;
        background-color: #fff;
        width: 2.41rem;
        border-radius: 0.03rem 0.03rem 0.03rem 0.03rem;
        box-shadow: 0.04rem 0.05rem 0.27rem 0.01rem rgba(204, 204, 204, 0.2);
      }
      img {
        margin-bottom: 0.25rem;
        width: 100%;
      }
      .item_title {
        padding: 0 0.16rem;

        font-size: 0.1rem;
        text-align: left;
        color: #333333;
        margin-bottom: 0.1rem;
      }
      .item_time {
        text-align: left;
        font-size: 0.095rem;
        color: #808080;
        padding: 0 0.16rem;
        margin-bottom: 0.24rem;
      }
    }
    .more_btn {
      font-size: 18px;
      color: #02aeec;
      line-height: 30px;
      padding: 42px 0 0 0;
      cursor: pointer;
      width: 120px;
      margin: 0 auto;
    }
    // @media screen and (max-width: 1540px) {
    //   .news_center {
    //     .news_item {
    //       width: 300px;
    //       .item_title {
    //         font-size: 18px;
    //       }
    //       img {
    //         margin-bottom: 28px;
    //       }
    //       .item_time {
    //         font-size: 14px;
    //         margin-bottom: 27px;
    //       }
    //     }
    //   }
    // }
  }
  .partner_wrap {
    padding: 0.32rem 0;
    background: #fafafa;
    .partner_title {
      color: #333333;
      font-size: 36px;
      margin-bottom: 0.12rem;
      font-weight: 600;
    }
    .partner_des {
      margin-bottom: 0.13rem;

      color: #808080;
      font-size: 0.095rem;
    }
    img {
      margin: 0 auto;
      width: 100%;
      max-width: 8.51rem;
    }
  }
}

@media screen and (max-width: 1550px) {
  .home_page {
  }
}
.m_home_page {
  overflow-x: hidden;
  padding-top: 1.59rem;
  .swiper-container {
    .swiper-pagination {
      bottom: 0.5rem;
    }
  }
  .swiper_core {
    .swiper-pagination {
      bottom: -0.7rem;
    }
  }
  .swiper-container,
  .swiper_core {
    position: relative;

    .swiper-pagination-bullet {
      width: 20px;
      height: 4px;

      background: rgba(2, 174, 236, 0.5);
      border-radius: 0;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      width: 20px;
      height: 4px;
      background: #02aeec;
      border-radius: 0;
    }
    .swiper-slide {
      position: relative;

      img {
        width: 100%;
        display: block;
      }
    }
  }
  .core_wrap {
    background-color: #2d3740;
    padding: 1.2rem 0;

    .core_title {
      color: #fcfcfc;
      font-size: 0.49rem;
      margin-bottom: 18px;
      font-weight: 600;
    }
    .core_des {
      line-height: 0.55rem;
      color: #cccccc;
      padding: 0 0.43rem;
      font-size: 0.34rem;
      margin-bottom: 0.91rem;
    }
    .core_block {
      width: 100%;
      border: 0.43rem solid #2d3740;
      background: #6f787f;
      margin: 0 auto;
      border-radius: 0.09rem 0.09rem 0.09rem 0.09rem;
      border-top: none;
      border-bottom: none;
      position: relative;
      .block_img {
        position: relative;
        p {
          font-size: 0.4rem;
          position: absolute;
          bottom: 0.5rem;
          left: 0.4rem;
          color: #fff;
        }
      }
      .block_right {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        height: 6.23rem;
        &.center {
          .blocl_item {
            margin: 0.43rem 0.34rem;
          }
        }
        .blocl_item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3.5rem;
          padding: 0.33rem 0.47rem;
          height: 1.46rem;
          background: rgba(255, 255, 255, 0.2);
          box-shadow: 0.11rem 0.13rem 0.74rem 0.01rem rgba(204, 204, 204, 0.16);
          border-radius: 0.09rem 0.09rem 0.09rem 0.09rem;
          color: #f2f2f2;
          font-size: 0.29rem;
          margin: 0.23rem 0.34rem;
        }
      }
    }
  }
  .cloud_wrap {
    padding: 1.2rem 0.43rem;
    background: url(../assets/clound_m_bg.png) no-repeat;
    background-size: cover;
    .cloud_title {
      font-size: 0.45rem;
      color: #333333;
      margin-bottom: 0.56rem;
      font-weight: 600;
    }
    .cloud_des {
      color: #808080;
      line-height: 0.55rem;
      font-size: 0.32rem;
    }
    .cloud_line {
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      margin-top: 0.85rem;
      width: 100%;
      .cloud_bg1 {
        background: url(../assets/cloud_m_1.png) no-repeat;
      }
      .cloud_bg2 {
        background: url(../assets/cloud_m_2.png) no-repeat;
      }
      .cloud_bg3 {
        background: url(../assets/cloud_m_3.png) no-repeat;
      }
      .cloud_bg4 {
        background: url(../assets/cloud_m_4.png) no-repeat;
      }

      .cloud_item {
        width: 4.41rem;
        height: 2.08rem;
        color: #00394d;
        background-size: cover;
        font-size: 0.29rem;
        display: flex;
        align-items: center;
        padding: 0 0 0 0.4rem;
        margin-bottom: 0.32rem;
        img {
          width: 0.64rem;
          margin-right: 0.2rem;
        }
        p {
          font-size: 0.29rem;
        }
      }
    }
    .cloud_bg5 {
      background: url(../assets/cloud_m_5.png) no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.11rem;
      font-size: 0.29rem;
      img {
        width: 0.64rem;
        margin-right: 0.3rem;
      }
    }
  }

  .adv_wrap {
    background: url(../assets/adv_m_bg.png) no-repeat;
    background-size: cover;
    padding: 1.2rem 0.43rem 0.85rem;
    .adv_title {
      color: #fcfcfc;

      font-size: 0.45rem;
      margin-bottom: 0.56rem;
      font-weight: 600;
    }
    .adv_des {
      margin-bottom: 0.85rem;
      line-height: 0.55rem;
      color: #cccccc;

      font-size: 0.32rem;
    }
    .adv_line {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .adv_item {
        width: 4.41rem;
        background: #394856;
        border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
        opacity: 0.9;
        margin-bottom: 0.32rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0.88rem 0;
      }
      .num {
        margin-bottom: 0.32rem;

        font-size: 0.32rem;
        color: #02aeec;
        span {
          font-size: 0.64rem;
          font-weight: 600;
        }
      }
      .title {
        margin-bottom: 0.24rem;
        color: #f5f5f5;

        font-size: 0.32rem;
      }
      .des {
        line-height: 0.4rem;
        color: #cccccc;

        font-size: 0.27rem;
      }
    }
  }
  .tab_wrap {
    padding: 1.2rem 0.43rem 0;
    background: #fafafa;
    .tab_title {
      font-size: 0.45rem;
      color: #333333;
      margin-bottom: 0.56rem;
      font-weight: 600;
    }
    .tab_des {
      color: #808080;
      line-height: 0.55rem;
      font-size: 0.32rem;
      margin-bottom: 0.85rem;
    }
    .tab_center {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      img {
        width: 5.8rem;
        max-width: 100%;
        &:first-child {
          margin-top: -0.8rem;
          margin-right: -0.8rem;
        }
        &:last-child {
          transform: translateX(0.2rem);
          margin-top: -0.8rem;
          margin-left: -0.8rem;
        }
      }
      .vs {
        width: 0.8rem;
      }
    }
  }

  .news_wrap {
    background: url(../assets/news_bg.png);
    background-size: cover;
    padding: 1.2rem 0.43rem 0;
    .news_title {
      font-size: 0.45rem;
      color: #333333;
      margin-bottom: 0.56rem;
      font-weight: 600;
    }
    .news_des {
      color: #808080;
      line-height: 0.55rem;
      font-size: 0.32rem;
      margin-bottom: 0.85rem;
    }
    .news_center {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .news_item {
        width: 4.43rem;
        background: #ffffff;
        box-shadow: 0.11rem 0.12rem 0.69rem 0.01rem rgba(204, 204, 204, 0.2);
        border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
        margin-bottom: 0.29rem;
      }
      img {
        margin-bottom: 0.31rem;
        width: 100%;
      }
      .item_title {
        padding: 0 0.24rem;

        font-size: 0.29rem;
        text-align: left;
        color: #333333;
        margin-bottom: 0.25rem;
      }
      .item_time {
        text-align: left;

        font-size: 0.27rem;
        color: #808080;
        padding: 0 0.24rem;
        margin-bottom: 0.32rem;
      }
    }
    .more_btn {
      font-size: 0.29rem;
      color: #02aeec;

      line-height: 0.4rem;
      padding: 0.43rem 0 0.85rem 0;
      cursor: pointer;
      width: 2rem;
      margin: 0 auto;
    }
  }
  .partner_wrap {
    padding: 1.2rem 0;
    background: #fafafa;
    .partner_title {
      font-size: 0.45rem;
      color: #333333;
      margin-bottom: 0.56rem;
      font-weight: 600;
    }
    .partner_des {
      color: #808080;
      line-height: 0.55rem;
      font-size: 0.32rem;
      margin-bottom: 0.56rem;
    }
    img {
      margin: 0 auto;
      width: 100%;
      max-width: 1690px;
    }
  }
}
</style>
